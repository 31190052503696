import React from 'react';

import ReactDOM from 'react-dom';

import LanguageSwitcher from 'js/components/LanguageSwitcher/LanguageSwitcher';

const container = document.getElementById('language-switcher-react-app');
const supportedLocales =
  container && container.dataset.supportedLocales
    ? JSON.parse(container.dataset.supportedLocales)
    : [];

if (supportedLocales.length) {
  ReactDOM.render(
    <LanguageSwitcher supportedLocales={supportedLocales} />,
    container,
  );
}
